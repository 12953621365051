/**
 * BASE
 */
*,
code,
pre,
pre::before {
  box-sizing: border-box;
  font-family: 'Fira Mono', monospace;
}

html {
  font-size: 18px;
  color: white;
}

body {
  background: black;
  min-height: 100vh;
  overflow: hidden;
  padding: 2.5rem;
}

pre {
  margin: 0;
}

/**
  * WINDOW
  */
#window {
  height: calc(100vh - 5rem);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

/**
  * TASKBAR
  */
#taskbar {
  min-height: 2rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  background: rgb(19, 19, 19, 0.5);
  text-align: center;
  width: 100%;
}

#taskbar .buttons {
  top: 0.1rem;
  left: 1rem;
  display: flex;
  margin-left: 0.5rem;
}

.buttons * {
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
  border-radius: 100%;
  display: inline-block;
  height:0.75rem;
  width:0.75rem;
  margin-right: .5rem;
  cursor: pointer;
  z-index: 100;
}

.buttons #close {
  background-color: #f56565;
}

.buttons #minimize {
  background-color: #faf089;
}

.buttons #maximize {
  background-color: #68d391;
}

#taskbar .title {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
  margin-left: -5rem;
  z-index: 50;
}

.simplebar-track.simplebar-vertical {
  right: calc(2px - 1rem);
}

/**
  * CONTENT
  */
#content {
  padding: 0  1rem;
  min-height: 1px; /* For simplebar to work */
}

#content.prompt {
  padding: 0  1.5rem;
}

/**
  * COMMAND
  */
.command {
  margin-top: 0.5rem;
  margin-bottom: 2px;
  line-height: 1.4;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.command + .command {
  margin-top: 1rem;
}

#content.prompt .command::before {
  content: "$";
  color: rgb(187, 187, 187);
  margin-left: -1rem;
  margin-right: 0.38rem;
}

/**
  * OUTPUT
  */
.output {
  color: rgba(255, 255, 255, 0.6);
}

/**
  * OVERLAY
  */
#overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #2B3134;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

#overlay svg path,
#overlay svg rect{
  fill: #FF6700;
}

/**
  * TOAST
  */
#toast {
  position: fixed;
  top: 50%;
  left: -25%;
  right: -25%;
  text-align: center;
  background-color: black;
  z-index: -1;
  opacity: 0;
}

#toast.visible {
  opacity: 0.5;
  z-index: 9999;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
}